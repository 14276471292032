import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Plugins } from '@capacitor/core';
import { Observable } from 'rxjs';
const { Storage } = Plugins

@Injectable({
  providedIn: 'root'
})
export class LogInGuard implements CanActivate {
  constructor(private router: Router){}
  canActivate(route: ActivatedRouteSnapshot,state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return Storage.get({key: 'isLogged'}).then(res => {
      if (res.value == 'true') {
        return true;
      } else {
        this.router.navigateByUrl('/log-in');
      }
    });
  }
  
}
