import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Plugins } from '@capacitor/core';

const { Storage } = Plugins

@Injectable({
  providedIn: 'root'
})
export class CindeApiService {
  baseUrl = 'https://cinde-api.herokuapp.com'//'http://localhost:3001'//
  header: any; 

  constructor(private http: HttpClient) { 
    this.setHeader();   
  }

  setHeader(){
    Storage.get({ key: 'user' }).then(res => {
      if (res.value != 'null' && res.value != null) {        
        this.header = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${JSON.parse(res.value).ip_auth_token}`
        }
      } else {
        this.header = {
          'Content-Type': 'application/json'
        }
      }
    });  
  }

  /* REQUESTS WITHOUT AUTH TOKEN */
  auth(form){  
    const url = `${this.baseUrl}/users/auth`
    return this.http.post(url, {email: form.email, pass: form.pass}, {responseType:'json'})
  }

  forgotPassword(email){    
    const url = `${this.baseUrl}/users/forgot-password`
    return this.http.post(url, {email: email}, {responseType:'json'})
  }

  requestAccess(form){
    const url = `${this.baseUrl}/requests/access`
    return this.http.post(url, {description: form.description, name: form.name, email: form.email, company: form.company}, {responseType:'json'})
  }


  /* REQUESTS WITH AUTH TOKEN */
  signOut(){
    Storage.set({key: 'user', value: null});
    Storage.set({key: 'isLogged', value: 'false'});
  }

  saveToken(token){
    const url = `${this.baseUrl}/users/fcm-tokens`
    console.log(`Sending ${token} to ${url}`)
    return this.http.patch(url, {token: token}, {responseType:'json', headers: this.header})
  }

  // Working Groups Index
  getWorkingGroups(positionType=null){
    const url = `${this.baseUrl}/working-groups`
    return this.http.get(url, {responseType:'json', headers: this.header});
  }

  getUserWorkingGroups(){
    const url = `${this.baseUrl}/working-groups/user`
    return this.http.get(url, {responseType:'json', headers: this.header});
  }

  // Working Groups Show
  getWorkingGroup(groupId){
    const url = `${this.baseUrl}/working-groups/${groupId}/show`
    return this.http.get(url, {responseType:'json', headers: this.header})
  }

  joinWorkingGroup(groupId){
    const url = `${this.baseUrl}/working-groups/${groupId}/join`
    return this.http.post(url, {}, {responseType:'json', headers: this.header})
  }

  getWorkingGroupResources(groupId){
    const url = `${this.baseUrl}/working-groups/${groupId}/resources`
    return this.http.post(url, {}, {responseType:'json', headers: this.header})
  }

  getWorkingGroupMembers(groupId){
    const url = `${this.baseUrl}/working-groups/${groupId}/members`
    return this.http.post(url, {}, {responseType:'json', headers: this.header})
  }

  getEvents(groupId){
    const url = `${this.baseUrl}/working-groups/${groupId}/events`
    return this.http.get(url, {responseType:'json', headers: this.header})
  }

  getEvent(groupId, eventId){
    const url = `${this.baseUrl}/working-groups/${groupId}/events/${eventId}`
    return this.http.get(url, {responseType:'json', headers: this.header})
  }

  getUpdates(groupId){
    const url = `${this.baseUrl}/working-groups/${groupId}/updates`
    return this.http.post(url, {}, {responseType:'json', headers: this.header})
  }

  toggleUpdateLike(groupId,updateId){
    const url = `${this.baseUrl}/working-groups/${groupId}/updates/${updateId}`
    return this.http.patch(url, {}, {responseType:'json', headers: this.header})
  }

  // Resources
  getResources(positionType=null){
    const url = `${this.baseUrl}/resources`
    return this.http.post(url, {type: positionType}, {responseType:'json', headers: this.header})
  }

  // Request
  requestSupport(form){
    const url = `${this.baseUrl}/requests/support`
    return this.http.post(url, {category: form.category, description: form.description}, {responseType:'json', headers: this.header})
  }

  requestProfileUpdates(form){
    const url = `${this.baseUrl}/requests/profile-updates`
    return this.http.post(url, {description: form.description}, {responseType:'json', headers: this.header})
  }

  requestProject(form){
    const url = `${this.baseUrl}/requests/project`
    return this.http.post(url, {description: form.description}, {responseType:'json', headers: this.header})
  }

  requestAgendaChanges(form, agendaId){
    const url = `${this.baseUrl}/requests/agenda-changes`
    return this.http.post(url, {description: form.description, agenda_id: agendaId}, {responseType:'json', headers: this.header})
  }

  // Advertising
  getPromos(){    
    const url = `${this.baseUrl}/advertising/promo`
    return this.http.get(url, {responseType:'json', headers: this.header})
  }

  getAds(){    
    const url = `${this.baseUrl}/advertising/ads`
    return this.http.get(url, {responseType:'json', headers: this.header})
  }

  // Users
  updateUser(email, mobilePhone, officePhone, title){
    const url = `${this.baseUrl}/users/update`
    return this.http.patch(url, {email: email, mobile_phone: mobilePhone, office_phone: officePhone, title: title}, {responseType:'json', headers: this.header})
  }
  
  // Projects
  getProjects(positionType=null){
    const url = `${this.baseUrl}/projects?type=${positionType}`
    return this.http.get(url, {responseType:'json', headers: this.header})
  }

  getProject(projectId){
    const url = `${this.baseUrl}/projects/${projectId}`
    return this.http.get(url, {responseType:'json', headers: this.header})
  }

  getProjectAgenda(projectId, agendaId){
    const url = `${this.baseUrl}/projects/${projectId}/agendas/${agendaId}`
    return this.http.get(url, {responseType:'json', headers: this.header})
  }

  getProjectMembers(projectId){
    const url = `${this.baseUrl}/projects/${projectId}/members`
    return this.http.get(url, {responseType:'json', headers: this.header})
  }

  // Notifications
  getNotifications(){
    const url = `${this.baseUrl}/notifications`
    return this.http.get(url, {responseType:'json', headers: this.header})
  }

  updateNotificationAsRead(notificationId){
    const url = `${this.baseUrl}/notifications/${notificationId}/read`
    return this.http.patch(url, {}, {responseType:'json', headers: this.header})
  }

  hasUnreadNotifications(){
    const url = `${this.baseUrl}/notifications/unread`
    return this.http.get(url, {responseType:'json', headers: this.header})
  }
}