import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})

export class ResponsiveService {
  public platformString:any = 'desktop';

  constructor(private platform: Platform) {
    this.platformString = platform.is('desktop') ? 'desktop' : 'mobile';
  }
}