import { Component } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { FcmService } from './services/fcm.service';

const { Storage } = Plugins;
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  
  constructor(private platform: Platform,
    private router: Router,
    private fcm: FcmService) {
      this.initializeApp();
    }

  initializeApp() {
    this.platform.ready().then(() => {
      Storage.get({ key: 'user' }).then(res => {
        let currentUser = JSON.parse(res.value);
        if (currentUser){
          this.fcm.initPush(currentUser);
          this.router.navigateByUrl('/tabs/home');
        }else{
          this.router.navigateByUrl('/log-in');
        }       
      });
    });
  }
}
