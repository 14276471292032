import { Platform } from '@ionic/angular';
import { Injectable, NgZone } from '@angular/core';
import { Plugins, PushNotification, PushNotificationToken, PushNotificationActionPerformed, Capacitor } from '@capacitor/core';
import { Router } from '@angular/router';
import { CindeApiService } from './cinde-api.service';

const { PushNotifications } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class FcmService {
  currentUser: any;

  constructor(private platform: Platform,
    private router: Router,
    private cindeApi: CindeApiService,
    private zone: NgZone) { }

  initPush(user) {
    if (Capacitor.platform !== 'web') {
      this.registerPush();
      this.currentUser = user;
    }
  }

  private registerPush() {
    PushNotifications.requestPermission().then((permission) => {
      if (permission.granted) {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // TODO: No permission for push granted message
      }
    });

    PushNotifications.addListener(
      'registration',
      (token: PushNotificationToken) => {
        this.saveToken(token['value']);
      }
    );

    PushNotifications.addListener('registrationError', (error: any) => {
      console.log('Error: ' + JSON.stringify(error));
    });

    PushNotifications.addListener(
      'pushNotificationReceived',
      async (notification: PushNotification) => {
        console.log('Push received: ' + JSON.stringify(notification));
      }
    );

    PushNotifications.addListener('pushNotificationActionPerformed', async (notification: PushNotificationActionPerformed) => {
        const pushNotification = notification.notification.data;
        let path = '/tabs/home';

        console.log('Action performed: ' + JSON.stringify(notification.notification));
        if (pushNotification.type == 'working-group-resource') {
          path = `/tabs/working-groups/${pushNotification.id}`;
        } else if (pushNotification.type == 'resource-global') {
          path = `/tabs/resources`;
        } else if (pushNotification.type == 'working-group-new-event') {
          path = `/tabs/working-groups/${pushNotification.id}`;
        } else if (pushNotification.type == 'working-group-event'){
          path = `/tabs/working-groups/${pushNotification.id}`;
        } else if (pushNotification.type == 'working-group-update') {
          path = `/tabs/working-groups/${pushNotification.id}`;
        }

        this.zone.run(() => {
          this.router.navigate([path]);
        });
      }
    );
  }

  private saveToken(token) {
    if (!token) return;

    this.cindeApi.saveToken(token).subscribe(res => {
      console.log(res);
    });
  }
}